import React, { useState, useEffect, useRef, useContext } from "react";
import { useAuth, getAuthData } from "../../context/AuthContext";
import { getDocs } from "firebase/firestore";
import { Checkbox, CircularProgress, Grid, Tooltip } from "@mui/material";
import { Container } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Multiselect } from "multiselect-react-dropdown";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import error from "./error.svg";
import info from "./info.svg";
import error_storage from "./error_storage.svg";
import ownerImg from "./users.svg";
import ownerImgBlocked from "./owner_blocked.svg";
import firestoreRepository, {
  listenDeviceSyncChanges,
} from "../../repositories/firestoreRepository";
import { groupSessionsByDate } from "../../utils/sessionUtils";
import Layout from "../Layout";
import "./Dashboard.css";
import ModalEditSession from "../common/ModalEditSession";
import ModalDeleteSession from "../common/ModalDeleteSession";
import { Link } from "react-router-dom";
import ModalCreateSession from "../common/ModalSessionCreation";
import placeHolderThumbnail from "../../images/dashboard/background_cross.png";
import { Add } from "@mui/icons-material";
import axios from "axios";
import { analytics } from "../../firebase";
import { logEvent } from "firebase/analytics";
import FreeUser from "./free";
import EmptyDashboard from "./empty";
import cloudFunctionRepository from "../../repositories/cloudFunctionRepository";
import { Owner } from "../../utils/ownerUtils";
import { UploadContext } from "../App";
import inactive from "../../images/dashboard/Inactive.svg";
import selected from "../../images/dashboard/Select.svg";
import user from "../../images/dashboard/user.svg";
import ownerImgSelected from "../../images/dashboard/ownerImgSelected.svg";
import FreeOrg from "./free_org";

export default function Dashboard() {
  const { selectedOwner, setSelectedOwner } = useContext(UploadContext);
  const [ownerData, setOwnerData] = useState([]);
  const [checkedOwner, setCheckedOwner] = useState([]);
  const { currentUser } = useAuth();
  const [sessions, setSessions] = useState([]);
  const [sessionsByDate, setSessionsByDate] = useState([]);
  const [images, setImages] = useState({});
  const [videos, setVideos] = useState([]);
  const [patients, setPatients] = useState([]);
  const [allPatients, setAllPatients] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [userData, setUserData] = useState([]);
  const [ownerFilter, setOwnerFilter] = useState(false);
  const [sessionWithActiveModal, setSessionWithActiveModal] = useState(null);
  const [displaySearch, setDisplaySearch] = useState(() => {
    const storedValue = JSON.parse(
      sessionStorage.getItem("persistSearchParams")
    );
    if (
      storedValue &&
      (storedValue.patientId !== null ||
        (storedValue.tags && storedValue.tags.length !== 0) ||
        (storedValue.dateRange && storedValue.dateRange[0].startDate !== null))
    ) {
      return true;
    } else {
      return false;
    }
  });
  const [displayDatePicker, setDisplayDatePicker] = useState(false);
  const [needReload, setNeedReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [largestSubscriptionId, setLargestSubscriptionId] = useState();
  const lastDeviceSyncRef = useRef();
  const setLastDeviceSync = (data) => {
    lastDeviceSyncRef.current = data;
  };
  const [subscriptionData, setSubscriptionData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = window.innerWidth <= 600;
  const [hide80, setHide80] = useState(() => {
    const storedValue = localStorage.getItem("isHiden80");
    return storedValue ? JSON.parse(storedValue) : false;
  });
  const [hide90, setHide90] = useState(() => {
    const storedValue = localStorage.getItem("isHiden90");
    return storedValue ? JSON.parse(storedValue) : false;
  });
  const [authData, setAuthData] = useState({});
  const entityId = useRef(null);
  const isOrgUser = useRef(null);
  const [ownerInput, setOwnerInput] = useState("");

  // Ratio of 1:3 increment:total leads to best UI experience
  const LOADED_SESSIONS = 150;
  const LOAD_INCREMENT = 50;

  const onScrollInProgress = useRef(false);
  const lastScrollTime = useRef(Date.now());
  const debounceTimer = useRef(null);
  const filterRef = useRef(null);

  const dltPermission = useRef();
  const rdPermissions = useRef();
  const shrPermissions = useRef();
  const wrtPermissions = useRef();

  const startSessionIdx = useRef(
    parseInt(sessionStorage.getItem("startSessionIdx") || 0, 10)
  );
  const endSessionIdx = useRef(
    parseInt(sessionStorage.getItem("endSessionIdx") || LOADED_SESSIONS, 10)
  );
  const queryIdx = useRef(
    JSON.parse(sessionStorage.getItem("persistQueryIdx"))
  );

  const persistedSearchParams = JSON.parse(
    sessionStorage.getItem("persistSearchParams")
  );

  const searchParams = useRef(
    persistedSearchParams
      ? {
          ...persistedSearchParams,
          dateRange: persistedSearchParams.dateRange.map((range) => ({
            ...range,
            startDate: range.startDate ? new Date(range.startDate) : null,
            endDate: range.endDate
              ? new Date(new Date(range.endDate))
              : new Date(new Date().setHours(23, 59, 59, 999)),
          })),
        }
      : {
          patientGlobalId: null,
          patientId: null,
          dateRange: [
            {
              startDate: null,
              endDate: new Date(new Date().setHours(23, 59, 59, 999)),
              key: "selection",
            },
          ],
          tags: [],
          owner: null,
        }
  );

  const lastScrollPosition = useRef(
    parseInt(sessionStorage.getItem("scrollPosition") || 0, 10)
  );

  useEffect(() => {
    if (isOrgUser.current === true) {
      loadOrgData(entityId.current).then((data) => {
        setLastDeviceSync(data.lastDeviceSync);
        setLargestSubscriptionId(data.stripeSubscriptionId || 0);
      });

      firestoreRepository.getUserClaims(currentUser.uid).then((claims) => {
        firestoreRepository.getOrgData(claims.orgId).then((orgData) => {
          const currentUserData = orgData.users[currentUser.uid];
          if (currentUserData) {
            dltPermission.current = currentUserData.pDlt;
            rdPermissions.current = currentUserData.pRd;
            shrPermissions.current = currentUserData.pShr;
            wrtPermissions.current = currentUserData.pWrt;
            if (currentUserData.pRd === 1) {
              updateSearchParams(
                [currentUser.uid],
                "owner",
                searchParams.current
              );
            } else if (currentUserData.pRd === 2 && queryIdx.current == null) {
              queryIdx.current = 0;
              setNeedReload(!needReload);
            }
          } else {
            console.error("Current user data not found in organization data.");
          }
          const usersData = Object.keys(orgData.users)
            .map((userId) => ({
              id: userId,
              name: orgData.users[userId].name,
              clrIdx: orgData.users[userId].clrIdx,
              roleId: orgData.users[userId].roleId,
            }))
            .filter((user) => user.roleId === 1);
          setOwnerData(usersData);
        });
      });
    } else if (isOrgUser.current === false) {
      loadUserData(currentUser.uid).then((data) => {
        setUserData(data);
        setLastDeviceSync(data.lastDeviceSync);
        setLargestSubscriptionId(
          Math.max(
            data.stripeSubscriptionId || 0,
            data.iosSubscriptionId || 0,
            data.androidSubscriptionId || 0,
            data.premiumAndroid ? 2 : 0,
            data.premiumIOS ? 2 : 0
          )
        );
        if (queryIdx.current == null) {
          queryIdx.current = 0;
          setNeedReload(!needReload);
        }
      });
    }

    countConnectAccessed(currentUser.uid);

    if (searchParams.current && searchParams.current.owner) {
      setCheckedOwner(searchParams.current.owner);
    }

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("beforeunload", handleBeforeUnload);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      setCheckedOwner([]);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isOrgUser.current]);

  useEffect(() => {
    if (entityId.current === null || isOrgUser.current === null) {
      return;
    }

    loadData(
      entityId.current,
      isOrgUser.current,
      queryIdx.current,
      startSessionIdx.current,
      endSessionIdx.current,
      searchParams.current
    );
  }, [needReload]);

  useEffect(() => {
    getAuthData()
      .then((data) => {
        setAuthData(data);
        // Set entityId and isOrgUser based on the user's claims
        if (!data.claims.orgId) {
          entityId.current = currentUser.uid;
          isOrgUser.current = false;
        } else {
          entityId.current = data.claims.orgId;
          isOrgUser.current = true;
        }
        const fetchData = async () => {
          const loadDataCallback = () => {
            // Callback function to reload data when syncing changes are detected

            loadData(
              entityId.current,
              isOrgUser.current,
              queryIdx.current,
              startSessionIdx.current,
              endSessionIdx.current,
              searchParams.current
            );
          };
          // Set up listener for device sync changes
          const unsubscribe = await listenDeviceSyncChanges(
            entityId.current,
            isOrgUser.current,
            lastDeviceSyncRef,
            setLastDeviceSync,
            loadDataCallback,
            debounceTimer
          );

          // Clean up the listener when the component unmounts
          return () => unsubscribe();
        };

        fetchData();
        setNeedReload(!needReload);
        loadSubscriptionData(data.token).then((subscriptionData) => {
          setSubscriptionData(subscriptionData);
          window.Intercom("update", {
            subscription_end_date: subscriptionData.currentPeriodEnd
              ? subscriptionData.currentPeriodEnd
              : null,
          });
        });
      })
      .catch((error) => {
        console.error("Error getting auth data: ", error);
      });
  }, [currentUser]);

  useEffect(() => {
    localStorage.setItem("isHiden80", JSON.stringify(hide80));
  }, [hide80]);

  useEffect(() => {
    localStorage.setItem("isHiden90", JSON.stringify(hide90));
  }, [hide90]);

  const createBillingPortalSession = async () => {
    if (!authData.token) {
      console.error("User is not authenticated.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/createStripeBillingPortalConfig`,

        {
          // Any data you want to send to the Cloud Function
          // For example, you can send an empty object if no data is needed.
        },
        {
          headers: {
            Authorization: `Bearer ${authData.token}`,
          },
        }
      );

      handleBillingPortalClick(response.data.portalSession);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleBillingPortalClick = (billingPortalUrl) => {
    if (billingPortalUrl) {
      window.location.href = billingPortalUrl;
    }
  };

  const loadData = async (
    entityId,
    isOrgUser,
    queryIdx,
    startIdx,
    endIdx,
    searchParams
  ) => {
    loadSessions(
      entityId,
      isOrgUser,
      queryIdx,
      startIdx,
      endIdx,
      searchParams
    ).then(([sessions, queryStatus]) => {
      if (queryStatus === "empty") {
        const emptyData = () => {
          setSessions([]);
          setSessionsByDate([]);
          setImages({});
          setVideos([]);
          setPatients([]);
        };
        setIsLoading(false);

        // User already at the end of scroll or there is no sessions, return
        return emptyData();
      }
      if (queryStatus === "endOfScroll") {
        setIsLoading(false);
        return null;
      }

      if (queryStatus === "success") {
        setSessions(sessions);
        setSessionsByDate(groupSessionsByDate(sessions));
        // Delay scrollTo until after the page has finished rendering
        setTimeout(() => {
          window.scrollTo(0, lastScrollPosition.current);
        }, 100);
        loadImages(entityId, isOrgUser, sessions).then((images) => {
          setImages(images);
        });
        loadVideos(entityId, isOrgUser, sessions).then((videos) => {
          setVideos(videos);
        });
        loadPatients(entityId, isOrgUser, sessions).then((patients) => {
          setPatients(patients);
        });
        setIsLoading(false);
      }
    });
    // Since we need all tags, they are independent from the session query
    loadAllTags(entityId, isOrgUser).then((tags) => {
      setAllTags(tags);
    });
    loadAllPatients(entityId, isOrgUser).then((patients) => {
      setAllPatients(patients);
    });
  };

  /**
   * Loads sessions from the Firestore database.
   *
   * @param {string} entityId - The ID of either the user or the organization.
   * @param {boolean} isOrgUser - A boolean indicating whether the user belongs to an organization.
   * @param {number} startIdx - The starting index of the sessions to load.
   * @param {number} endIdx - The ending index of the sessions to load.
   * @returns {Promise<Array<Object>>|null} - A promise that resolves to an array of session data, or null if no more sessions need to be loaded.
   */
  const loadSessions = async (
    entityId,
    isOrgUser,
    queryIdx,
    startIdx,
    endIdx,
    searchParams
  ) => {
    try {
      const queryData = await firestoreRepository.querySessionsDataByQueryIdx(
        entityId,
        isOrgUser,
        queryIdx,
        endIdx,
        searchParams
      );

      if (queryData.length === 0) {
        return [[], "empty"];
      }
      if (queryData.length == endIdx - LOAD_INCREMENT && startIdx != 0) {
        // User already at the end of scroll, reset the start and end index and return null to stop loading
        startSessionIdx.current = Math.max(
          queryData.length - LOADED_SESSIONS,
          0
        );
        endSessionIdx.current = Math.max(queryData.length, LOADED_SESSIONS);
        return [[], "endOfScroll"];
      } else if (queryData.length < endIdx) {
        // First time user reaches the end of scroll, correct the start and end index and return the data
        startSessionIdx.current = Math.max(
          queryData.length - LOADED_SESSIONS,
          0
        );
        endSessionIdx.current = Math.max(queryData.length, LOADED_SESSIONS);
        return [queryData.slice(startSessionIdx.current), "success"];
      } else {
        // User is in the middle of the scroll, return the data
        return [queryData.slice(startIdx), "success"];
      }
    } catch (error) {
      console.error("Error loading sessions: ", error);
      return [[], "error"];
    }
  };

  /**
   * Loads images for a given user and session data.
   *
   * @param {string} entityId - The ID of either the user or the organization.
   * @param {boolean} isOrgUser - A boolean indicating whether the user belongs to an organization.
   * @param {Array} sessionData - An array of session data.
   * @returns {Object} - An object mapping session global IDs to session images.
   */
  const loadImages = async (entityId, isOrgUser, sessionData) => {
    let sessionImagesMap = {};
    await Promise.all(
      sessionData.map(async (session) => {
        const sessionImages = await firestoreRepository.getImagesBySession(
          entityId,
          isOrgUser,
          session
        );
        sessionImagesMap[session.globalId] = sessionImages;
      })
    );
    return sessionImagesMap;
  };

  /**
   * Loads videos for a given user and session data.
   *
   * @param {string} entityId - The ID of either the user or the organization.
   * @param {boolean} isOrgUser - A boolean indicating whether the user belongs to an organization.
   * @param {Array} sessionData - An array of session data.
   * @returns {Object} - An object mapping session global IDs to session videos.
   */
  const loadVideos = async (entityId, isOrgUser, sessionData) => {
    let sessionVideosMap = {};
    await Promise.all(
      sessionData.map(async (session) => {
        const sessionVideos = await firestoreRepository.getVideosBySession(
          entityId,
          isOrgUser,
          session
        );
        sessionVideosMap[session.globalId] = sessionVideos;
      })
    );
    return sessionVideosMap;
  };

  const loadPatients = async (entityId, isOrgUser, sessionData) => {
    let sessionPatientMap = {};
    await Promise.all(
      sessionData.map(async (session) => {
        const sessionPatient = await firestoreRepository.getPatientBySession(
          entityId,
          isOrgUser,
          session
        );
        sessionPatientMap[session.globalId] = sessionPatient;
      })
    );
    return sessionPatientMap;
  };

  const loadAllPatients = async (entityId, isOrgUser) => {
    const patientsData = await firestoreRepository.getAllPatients(
      entityId,
      isOrgUser
    );
    return patientsData;
  };

  const loadAllTags = async (entityId, isOrgUser) => {
    const tags = await firestoreRepository.getAllTags(entityId, isOrgUser);
    return tags;
  };

  const countConnectAccessed = async (userId) => {
    const count = await firestoreRepository.setConnectAccessed(userId);
    return count;
  };

  const loadSubscriptionData = async (authToken) => {
    try {
      const subscriptionData =
        await cloudFunctionRepository.getSubscriptionData(authToken);
      return subscriptionData;
    } catch (error) {
      console.error("Error getting subscription data: ", error);
    }
  };

  const loadUserData = async (userId) => {
    const data = await firestoreRepository.getUserData(userId);
    return data;
  };

  const loadOrgData = async (orgId) => {
    const data = await firestoreRepository.getOrgData(orgId);
    return data;
  };
  const updateSearchParams = (newValue, paramField, currentSearchParams) => {
    currentSearchParams[paramField] = newValue;
    const isPatientSearchActive =
      currentSearchParams.patientId != null &&
      currentSearchParams.patientId != "";
    const isDateSearchActive =
      currentSearchParams.dateRange[0].startDate != null &&
      currentSearchParams.dateRange[0].endDate != null;
    const isTagSearchActive = currentSearchParams.tags.length > 0;
    // Reset queryIdx depending on the active search parameters
    if (
      currentSearchParams.owner != null &&
      currentSearchParams.owner.length > 0 &&
      isTagSearchActive &&
      isPatientSearchActive &&
      isDateSearchActive
    ) {
      queryIdx.current = 15;
    } else if (
      currentSearchParams.owner != null &&
      currentSearchParams.owner.length > 0 &&
      isTagSearchActive &&
      isDateSearchActive
    ) {
      queryIdx.current = 14;
    } else if (
      currentSearchParams.owner != null &&
      currentSearchParams.owner.length > 0 &&
      isTagSearchActive &&
      isPatientSearchActive
    ) {
      queryIdx.current = 13;
    } else if (
      currentSearchParams.owner != null &&
      currentSearchParams.owner.length > 0 &&
      isPatientSearchActive &&
      isDateSearchActive
    ) {
      queryIdx.current = 12;
    } else if (
      currentSearchParams.owner != null &&
      currentSearchParams.owner.length > 0 &&
      isTagSearchActive
    ) {
      queryIdx.current = 11;
    } else if (
      currentSearchParams.owner != null &&
      currentSearchParams.owner.length > 0 &&
      isPatientSearchActive
    ) {
      queryIdx.current = 10;
    } else if (
      currentSearchParams.owner != null &&
      currentSearchParams.owner.length > 0 &&
      isDateSearchActive
    ) {
      queryIdx.current = 9;
    } else if (
      currentSearchParams.owner != null &&
      currentSearchParams.owner.length > 0
    ) {
      queryIdx.current = 8;
    } else if (
      isPatientSearchActive &&
      isDateSearchActive &&
      isTagSearchActive
    ) {
      queryIdx.current = 7;
    } else if (isPatientSearchActive && isTagSearchActive) {
      queryIdx.current = 6;
    } else if (isDateSearchActive && isTagSearchActive) {
      queryIdx.current = 5;
    } else if (isPatientSearchActive && isDateSearchActive) {
      queryIdx.current = 4;
    } else if (isTagSearchActive) {
      queryIdx.current = 3;
    } else if (isDateSearchActive) {
      queryIdx.current = 2;
    } else if (isPatientSearchActive) {
      queryIdx.current = 1;
    } else {
      queryIdx.current = 0;
    }

    searchParams.current = currentSearchParams;
    startSessionIdx.current = 0;
    endSessionIdx.current = LOADED_SESSIONS;
    setNeedReload(!needReload);
  };

  const hideSearchBar = () => {
    setDisplaySearch(false);
    setDisplayDatePicker(false);
  };

  const clearSearchParams = () => {
    searchParams.current = {
      patientGlobalId: null,
      patientId: null,
      dateRange: [{ startDate: null, endDate: new Date(), key: "selection" }],
      tags: [],
      owner: null,
    };

    queryIdx.current = 0;
    startSessionIdx.current = 0;
    endSessionIdx.current = LOADED_SESSIONS;
    sessionStorage.removeItem("persistSearchParams");
    sessionStorage.removeItem("persistQueryIdx");
    setNeedReload(!needReload);
  };

  const persistStorageParams = () => {
    // Save the current scroll position in sessionStorage
    sessionStorage.setItem(
      "scrollPosition",
      lastScrollPosition.current.toString()
    );
    sessionStorage.setItem(
      "startSessionIdx",
      startSessionIdx.current.toString()
    );
    sessionStorage.setItem("endSessionIdx", endSessionIdx.current.toString());
    sessionStorage.setItem(
      "persistSearchParams",
      JSON.stringify(searchParams.current)
    );
    sessionStorage.setItem("persistQueryIdx", JSON.stringify(queryIdx.current));
  };

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setOwnerFilter(false);
    }
  };

  const handleBeforeUnload = () => {
    // only gets triggered when the user refreshes the page or closes the tab
    // Using react link method for navigation avoids resetting params
    sessionStorage.removeItem("scrollPosition");
    sessionStorage.removeItem("startSessionIdx");
    sessionStorage.removeItem("endSessionIdx");
    sessionStorage.removeItem("persistSearchParams");
    sessionStorage.removeItem("persistQueryIdx");
  };

  const handleScroll = () => {
    const now = Date.now();
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    if (now - lastScrollTime.current > 500) {
      lastScrollTime.current = now;
      const windowHeight = window.innerHeight;
      const totalHeight = document.documentElement.scrollHeight;
      // Calculate 25% of the total scrollable height
      const scrollThreshold = totalHeight * 0.25;
      // Determine scroll direction
      const scrollDirection =
        scrollPosition > lastScrollPosition.current ? "down" : "up";

      if (
        scrollDirection === "down" &&
        windowHeight + scrollPosition >= totalHeight - scrollThreshold
      ) {
        onScrollDown();
      }
      if (
        scrollDirection === "up" &&
        scrollPosition < scrollThreshold &&
        scrollPosition > 100
      ) {
        onScrollUp();
      }
    }
    // Update last scroll position
    lastScrollPosition.current = scrollPosition;
  };

  const onScrollDown = () => {
    if (onScrollInProgress.current) {
      return;
    }
    onScrollInProgress.current = true;
    startSessionIdx.current += LOAD_INCREMENT;
    endSessionIdx.current += LOAD_INCREMENT;
    loadData(
      entityId.current,
      isOrgUser.current,
      queryIdx.current,
      startSessionIdx.current,
      endSessionIdx.current,
      searchParams.current
    ).then(() => {
      onScrollInProgress.current = false;
    });
  };

  const onScrollUp = () => {
    if (onScrollInProgress.current) {
      return;
    }
    if (startSessionIdx.current == 0) {
      // User already at the top of scroll
      return;
    }
    onScrollInProgress.current = true;
    if (startSessionIdx.current < LOAD_INCREMENT) {
      // First time user reaches the top of scroll, correct the start and end index
      startSessionIdx.current = 0;
      endSessionIdx.current = LOADED_SESSIONS;
    } else {
      startSessionIdx.current -= LOAD_INCREMENT;
      endSessionIdx.current -= LOAD_INCREMENT;
    }
    loadData(
      entityId.current,
      isOrgUser.current,
      queryIdx.current,
      startSessionIdx.current,
      endSessionIdx.current,
      searchParams.current
    ).then(() => {
      onScrollInProgress.current = false;
    });
  };

  const handleCheck = (userId) => {
    const newCheckedUsers = checkedOwner.includes(userId)
      ? checkedOwner.filter((id) => id !== userId)
      : [...checkedOwner, userId];

    setCheckedOwner(newCheckedUsers);
    setSelectedOwner(newCheckedUsers[0] || "");

    updateSearchParams(newCheckedUsers, "owner", searchParams.current);
  };

  function getColorByClrIdx(clrIdx) {
    const colorMap = {
      0: "#F0F0F2",
      1: "#BAA2D6",
      2: "#64BAAA",
      3: "#F2B56B",
      4: "#F1E7FF",
      5: "#C4FCF0",
      6: "#F2DEC4",
      7: "#8A6BBF",
      8: "#4C8076",
      9: "#F28705",
      10: "#8F4FFF",
      11: "#00C5B7",
      12: "#FFA100",
    };

    return colorMap[clrIdx] || "#F0F0F2";
  }

  //Analytics
  const addIntercomEvent = () => {
    window.Intercom("trackEvent", "open-search-bar");
  };

  const analyticsSessionSearchPatient = () => {
    logEvent(analytics, "session_search_patient", {
      button_name: "session_search_patient",
    });
  };

  const analyticsSessionSearchDate = () => {
    logEvent(analytics, "session_search_date", {
      button_name: "session_search_date",
    });
  };

  const analyticsSessionSearchTag = () => {
    logEvent(analytics, "session_search_tag", {
      button_name: "session_search_tag",
    });
  };

  const analyticsSessionOpen = () => {
    logEvent(analytics, "session_open", {
      button_name: "session_open",
    });
  };

  if (!userData) {
    return (
      <Layout>
        <FreeUser></FreeUser>
      </Layout>
    );
  }

  return (
    <Layout>
      {userData && (
        <div>
          {largestSubscriptionId > 0 ? (
            <Container style={{ maxWidth: "none" }}>
              {subscriptionData.hasActiveSubscription === true &&
              subscriptionData.validPaymentMethod === false &&
              isMobile === false ? (
                <div
                  className="payment_banner_div"
                  style={{
                    display:
                      (userData &&
                        userData.storageEmailLastThreshold === 0.8 &&
                        !hide80) ||
                      (userData && userData.storageEmailLastThreshold === 1) ||
                      (userData &&
                        userData.storageEmailLastThreshold === 0.9 &&
                        !hide90)
                        ? "none"
                        : "flex",
                  }}
                >
                  <div className="flex_div">
                    <img src={error} className="payment_method_image"></img>
                    <div>
                      <div className="banner_text">Enter a Payment Method</div>
                      <div>
                        Add Payment Method by{" "}
                        <b>{subscriptionData.currentPeriodEnd}</b> to continue
                        using MicroREC Connect.
                      </div>
                    </div>
                  </div>
                  <button
                    className="billing_portal"
                    onClick={createBillingPortalSession}
                  >
                    {loading ? "Loading..." : "Add Payment Method"}
                  </button>
                </div>
              ) : (
                ""
              )}
              {userData &&
              userData.storageEmailLastThreshold === 0.8 &&
              hide80 === false ? (
                <Grid
                  container
                  xs={12}
                  justifyContent={{ xs: "space-between", md: "center" }}
                  className="top_bar_banner"
                >
                  <Grid item xs={1} md={1}>
                    <img src={info}></img>
                  </Grid>
                  <Grid item xs={9} md={7}>
                    <div>
                      <div className="banner_text">
                        Your cloud storage is 80% full.
                      </div>
                      <div>
                        Maintain seamless data uploads by upgrading to a higher
                        plan.
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    className="centered_flex_div"
                    marginTop={{ xs: "1em", md: "0" }}
                  >
                    <button
                      className="button_no_style hide_button"
                      onClick={() => setHide80(true)}
                    >
                      Don't show again
                    </button>
                    <button
                      onClick={createBillingPortalSession}
                      className="button_no_style manage_subscription"
                    >
                      {loading ? "Loading..." : "Manage"}
                    </button>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              {userData &&
              userData.storageEmailLastThreshold === 0.9 &&
              hide90 === false ? (
                <Grid
                  container
                  xs={12}
                  justifyContent={{ xs: "space-between", md: "center" }}
                  className="top_bar_banner"
                >
                  <Grid item xs={1} md={1}>
                    <img src={info}></img>
                  </Grid>
                  <Grid item xs={9} md={7}>
                    <div>
                      <div className="banner_text">
                        Your cloud storage is 90% full.{" "}
                      </div>
                      <div>
                        Maintain seamless data uploads by upgrading to a higher
                        plan.
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    className="centered_flex_div"
                    marginTop={{ xs: "1em", md: "0" }}
                  >
                    <button
                      className="button_no_style hide_button"
                      onClick={() => setHide90(true)}
                    >
                      Don't show again
                    </button>
                    <button
                      onClick={createBillingPortalSession}
                      className="button_no_style manage_subscription"
                    >
                      {loading ? " Loading..." : "Manage"}
                    </button>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              {userData && userData.storageEmailLastThreshold === 1 ? (
                <Grid
                  container
                  xs={12}
                  justifyContent={{ xs: "space-between", md: "center" }}
                  className="top_bar_banner"
                >
                  <Grid item xs={1} md={1}>
                    <img src={error_storage}></img>
                  </Grid>
                  <Grid item xs={9} md={7}>
                    <div>
                      <div className="banner_text">
                        You are out of cloud storage.{" "}
                      </div>
                      <div>
                        To make sure your data is uploaded, please upgrade to a
                        higher plan.
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    className="centered_flex_div"
                    marginTop={{ xs: "1em", md: "0" }}
                  >
                    <button
                      onClick={createBillingPortalSession}
                      className="button_no_style manage_full_subscription"
                    >
                      {loading ? "Loading..." : "Manage"}
                    </button>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}

              <Grid container className="top_bar_container">
                {(userData && userData.storageEmailLastThreshold === 1) ||
                wrtPermissions.current === 0 ? (
                  <Tooltip
                    title={
                      wrtPermissions.current === 0
                        ? "You don't have the required permissions to create a new session"
                        : ""
                    }
                  >
                    <button className="gray_new_session button_no_style ">
                      <div className="centered_items_flex_div">
                        <IconButton
                          type="button"
                          sx={{ p: "10px", color: "#999999" }}
                          aria-label="search"
                        >
                          <Add />
                        </IconButton>
                        <div className="inactive_session">New Session</div>
                      </div>
                    </button>
                  </Tooltip>
                ) : (
                  entityId.current && (
                    <ModalCreateSession
                      allPatients={allPatients}
                      allTagsArray={allTags}
                      entityId={entityId.current}
                      isOrgUser={isOrgUser.current}
                      wrtPermissions={wrtPermissions.current}
                      currentUser={currentUser.uid}
                    />
                  )
                )}
                {isOrgUser.current === true ? (
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    {rdPermissions.current === 1 ? (
                      <Tooltip title="You don't have the required permissions to filter by owner">
                        <button
                          className="refresh_button button_no_style"
                          style={{
                            backgroundColor: "#999999",
                          }}
                        >
                          <>
                            <IconButton
                              type="button"
                              sx={{ p: "10px", color: "white" }}
                              aria-label="owner"
                            >
                              <img src={ownerImgBlocked}></img>
                            </IconButton>
                            <div
                              className="button_text"
                              style={{ color: "white" }}
                            >
                              Owner
                            </div>
                          </>
                        </button>
                      </Tooltip>
                    ) : (
                      <button
                        className="refresh_button button_no_style"
                        style={{
                          backgroundColor:
                            ownerFilter || checkedOwner.length > 0
                              ? "#F1E7FF"
                              : "white",
                        }}
                        onClick={() => {
                          setOwnerFilter(!ownerFilter);
                        }}
                      >
                        {checkedOwner.length > 0 || ownerFilter ? (
                          <>
                            <IconButton
                              type="button"
                              sx={{
                                p: "10px",
                                color: "#999999",
                              }}
                              aria-label="owner"
                            >
                              <img src={ownerImgSelected}></img>
                            </IconButton>

                            <div className="button_text owner_text">
                              {checkedOwner.length > 0 ? (
                                <>
                                  <span className="font_weight_500">
                                    Owner:{" "}
                                  </span>
                                  {checkedOwner.length === 1 ? (
                                    ownerData.find(
                                      (owner) => owner.id === checkedOwner[0]
                                    )?.name || "Unassigned"
                                  ) : (
                                    <>
                                      {ownerData.find(
                                        (owner) => owner.id === checkedOwner[0]
                                      )?.name || "Unassigned"}
                                      <span className="checked_owner_number">
                                        +{checkedOwner.length - 1}
                                      </span>
                                    </>
                                  )}
                                </>
                              ) : (
                                "Owner"
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <IconButton
                              type="button"
                              sx={{ p: "10px", color: "#999999" }}
                              aria-label="owner"
                            >
                              <img src={ownerImg}></img>
                            </IconButton>
                            <div className="button_text">Owner</div>
                          </>
                        )}
                      </button>
                    )}
                    {ownerFilter && (
                      <div ref={filterRef} className="owner_filter_div">
                        <div className="font_weight_600">
                          Filter the dashboard by owner
                        </div>
                        <div className="margin_top_1">
                          Click on the people you want to filter sessions from
                        </div>
                        <div className="margin_top_1">
                          <input
                            className="input_style margin_bottom_0"
                            type="text"
                            placeholder="Search owner..."
                            value={ownerInput}
                            onChange={(e) => setOwnerInput(e.target.value)}
                          />

                          {ownerData
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .filter((user) =>
                              user.name
                                .toLowerCase()
                                .includes(ownerInput.toLowerCase())
                            )
                            .map((user) => (
                              <div className="checkbox_owner">
                                <Checkbox
                                  icon={<img src={inactive} />}
                                  checkedIcon={<img src={selected} />}
                                  checked={checkedOwner.includes(user.id)}
                                  onChange={(e) => {
                                    const newCheckedUsers =
                                      checkedOwner.includes(user.id)
                                        ? checkedOwner.filter(
                                            (id) => id !== user.id
                                          )
                                        : [...checkedOwner, user.id];

                                    setCheckedOwner(newCheckedUsers);
                                    setSelectedOwner(newCheckedUsers[0] || "");

                                    updateSearchParams(
                                      newCheckedUsers,
                                      "owner",
                                      searchParams.current
                                    );
                                  }}
                                  // onChange={() => handleCheck(user.id)}
                                />
                                <div
                                  className="checkbox_owner_name"
                                  style={{
                                    backgroundColor: getColorByClrIdx(
                                      user.clrIdx
                                    ),
                                  }}
                                >
                                  {user.name
                                    .split(" ")
                                    .map((n) => n[0])
                                    .join("")}
                                </div>
                                <div className="owner_name">{user.name}</div>
                              </div>
                            ))}
                          <div className="checkbox_owner">
                            <Checkbox
                              icon={<img src={inactive} />}
                              checkedIcon={<img src={selected} />}
                              checked={checkedOwner.includes("")}
                              onChange={() => handleCheck("")}
                            />
                            <div
                              className="checkbox_owner_name"
                              style={{
                                backgroundColor: "rgb(240, 240, 242)",
                              }}
                            >
                              <img src={user} className="width_20" />
                            </div>
                            Unassigned
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <button
                    className="search_button button_no_style"
                    onClick={() => {
                      addIntercomEvent();
                      setDisplaySearch(!displaySearch);
                    }}
                  >
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    >
                      <SearchIcon
                        style={{
                          color:
                            (queryIdx.current !== 0 &&
                              queryIdx.current !== 8) ||
                            displaySearch
                              ? "#8F4FFF"
                              : "#3D3838",
                        }}
                      />
                    </IconButton>

                    <div
                      className="button_text"
                      style={{
                        color:
                          (queryIdx.current !== 0 && queryIdx.current !== 8) ||
                          displaySearch
                            ? "#8F4FFF"
                            : "#3D3838",
                      }}
                    >
                      Search
                    </div>
                  </button>
                  {displaySearch ? (
                    <div className="search_bar_container">
                      <div className="search_bar_background">
                        <div className="search_bar">
                          <input
                            list="browsers"
                            type="text"
                            onChange={(e) =>
                              updateSearchParams(
                                e.target.value,
                                "patientId",
                                searchParams.current
                              )
                            }
                            value={searchParams.current.patientId || ""}
                            placeholder="Patient Id"
                            className="calendar_button margin_top_wrap"
                            id="patient_search"
                            onClick={analyticsSessionSearchPatient}
                          ></input>
                          <datalist id="browsers">
                            {Object.values(allPatients)
                              .reduce((unique, patient) => {
                                if (
                                  patient &&
                                  !unique.some(
                                    (obj) =>
                                      obj.canonicalPatientId ===
                                      patient.canonicalPatientId
                                  )
                                ) {
                                  unique.push(patient);
                                }

                                return unique;
                              }, [])
                              .sort((a, b) =>
                                a.canonicalPatientId.localeCompare(
                                  b.canonicalPatientId
                                )
                              )
                              .map((patient, index) => (
                                <option
                                  key={index}
                                  value={patient.canonicalPatientId}
                                ></option>
                              ))}
                          </datalist>
                          <div>
                            <button
                              onClick={() => {
                                setDisplayDatePicker(!displayDatePicker);
                                analyticsSessionSearchDate();
                              }}
                              className="calendar_button margin_top_wrap"
                            >
                              <CalendarMonthIcon
                                sx={{
                                  color: "#8F4FFF",
                                  marginRight: "0.5em",
                                }}
                              />
                              Select dates
                            </button>
                            {displayDatePicker === true ? (
                              <DateRange
                                id="selectDate"
                                editableDateInputs={true}
                                onChange={(item) => {
                                  updateSearchParams(
                                    [item.selection],
                                    "dateRange",
                                    searchParams.current
                                  );
                                }}
                                moveRangeOnFirstSelection={false}
                                showMonthAndYearPickers={false}
                                ranges={searchParams.current.dateRange}
                                className="name"
                                rangeColors={["#8F4FFF", "#FFCD78", "#FFCD78"]}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div onClick={analyticsSessionSearchTag}>
                            <Multiselect
                              options={allTags}
                              isObject={false}
                              onSelect={(selectedList) => {
                                updateSearchParams(
                                  selectedList,
                                  "tags",
                                  searchParams.current
                                );
                              }}
                              onRemove={(selectedList) => {
                                updateSearchParams(
                                  selectedList,
                                  "tags",
                                  searchParams.current
                                );
                              }}
                              selectedValues={searchParams.current.tags}
                              className="tags_button margin_top_wrap"
                              style={{
                                chips: {
                                  background: "#8F4FFF",
                                },
                                multiselectContainer: {
                                  color: "#8F4FFF",
                                },
                                searchBox: {
                                  border: "none",
                                  padding: "10px",
                                },

                                option: {
                                  color: "#8F4FFF",
                                  background: "white",
                                },
                              }}
                              placeholder="Tags"
                            />
                          </div>
                        </div>
                        <div className="flex_end_div">
                          <button
                            onClick={hideSearchBar}
                            className="button_no_style margin_1em"
                          >
                            Hide
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    style={{
                      backgroundColor:
                        (searchParams.current.patientId !== null ||
                          searchParams.current.tags.length !== 0 ||
                          searchParams.current.dateRange[0].startDate !==
                            null) &&
                        displaySearch
                          ? "white"
                          : displaySearch
                          ? "unset"
                          : "white",
                      paddingBottom: "10px",
                      marginTop:
                        (searchParams.current.patientId !== null ||
                          searchParams.current.tags.length !== 0 ||
                          searchParams.current.dateRange[0].startDate !==
                            null) &&
                        displaySearch
                          ? "8em"
                          : displaySearch
                          ? "8em"
                          : "0.5em",
                      width: "100%",
                      left: "0",
                      position: "absolute",
                      paddingTop:
                        searchParams.current.tags.length !== 0 ? "1em" : "0",
                    }}
                  >
                    {searchParams.current.patientId !== null ||
                    searchParams.current.tags.length !== 0 ||
                    searchParams.current.dateRange[0].startDate !== null ? (
                      <hr className="hr_style_search"></hr>
                    ) : (
                      ""
                    )}
                    <div className="filter_button filter_button_search">
                      {searchParams.current.patientId !== null ||
                      searchParams.current.tags.length !== 0 ||
                      searchParams.current.dateRange[0].startDate !== null ? (
                        <div className="margin_top_wrap font_weight_700">
                          Filter by:
                        </div>
                      ) : (
                        ""
                      )}
                      {searchParams.current.patientId !== null ? (
                        <div className="filter_by margin_top_wrap max_width_90 ">
                          <div className="display_search_patient">
                            {searchParams.current.patientId}
                          </div>
                          <button
                            onClick={() => {
                              updateSearchParams(
                                null,
                                "patientId",
                                searchParams.current
                              );
                            }}
                            className="button_no_style white_text"
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      {searchParams.current.dateRange[0].startDate !== null ? (
                        <div className="filter_by margin_top_wrap">
                          <div className="margin_right">
                            {`${new Intl.DateTimeFormat("default", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }).format(
                              new Date(
                                searchParams.current.dateRange[0].startDate
                              )
                            )}-${new Intl.DateTimeFormat("default", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }).format(
                              new Date(
                                searchParams.current.dateRange[0].endDate
                              )
                            )}`}
                          </div>
                          <button
                            onClick={() => {
                              updateSearchParams(
                                [
                                  {
                                    startDate: null,
                                    endDate: new Date(),
                                    key: "selection",
                                  },
                                ],
                                "dateRange",
                                searchParams.current
                              );
                            }}
                            className="button_no_style white_text"
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      {searchParams.current.tags.length !== 0 ? (
                        <div className="filter_by margin_top_wrap">
                          <div className="margin_right">Tags</div>
                          <button
                            onClick={() => {
                              updateSearchParams(
                                [],
                                "tags",
                                searchParams.current
                              );
                            }}
                            className="button_no_style white_text"
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      {searchParams.current.patientId !== null ||
                      searchParams.current.tags.length !== 0 ||
                      searchParams.current.dateRange[0].startDate !== null ? (
                        <div className="margin_top_wrap flex_centered_div">
                          <button
                            onClick={clearSearchParams}
                            className="button_no_style"
                          >
                            Clear all
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
              <div
                style={{
                  marginTop:
                    (searchParams.current.patientId !== null ||
                      searchParams.current.tags.length !== 0 ||
                      searchParams.current.dateRange[0].startDate !== null) &&
                    displaySearch
                      ? "14em"
                      : displaySearch
                      ? "9em"
                      : "2em",
                }}
              ></div>
              <div>
                {!isLoading &&
                  Object.keys(sessionsByDate).length === 0 &&
                  !(
                    queryIdx.current === 0 ||
                    queryIdx.current === 8 ||
                    queryIdx.current === null
                  ) && (
                    <div className="no_filtered_session">
                      There is no sessions matching the current filter.
                    </div>
                  )}
              </div>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <CircularProgress style={{ color: "rgb(143, 79, 255)" }} />
                </div>
              ) : Object.keys(sessionsByDate).length === 0 &&
                !displaySearch &&
                (queryIdx.current === 0 || queryIdx.current === 8) ? (
                <EmptyDashboard />
              ) : (
                <Grid>
                  {Object.keys(sessionsByDate).map((date, _) => (
                    <div key={date}>
                      <div className="date_separator">{date}:</div>
                      <Grid container>
                        {sessionsByDate[date].map((session, index) => [
                          <Grid
                            className="grid_session_item"
                            key={session.globalId}
                            sm={4}
                            item
                            xs={6}
                            md={3}
                            lg={2}
                            style={{ marginBottom: "1.5em" }}
                          >
                            {largestSubscriptionId !== 1 ? (
                              <div className="grid">
                                {(() => {
                                  const mediaArray =
                                    largestSubscriptionId > 1
                                      ? [
                                          ...(images[session.globalId] || []),
                                          ...(videos[session.globalId] || []),
                                        ]
                                      : [...(images[session.globalId] || [])];
                                  const sortedMediaArray = mediaArray.sort(
                                    (a, b) =>
                                      new Date(b.createdAt.toDate()) -
                                      new Date(a.createdAt.toDate())
                                  );
                                  return [...Array(4)].map((_, index) => {
                                    const media = sortedMediaArray[index];
                                    return (
                                      <React.Fragment
                                        key={`${session.globalId}_${index}`}
                                      >
                                        {media ? (
                                          <img
                                            src={media.thumbnailURL}
                                            className="grid_image"
                                            quality="50"
                                            loading="lazy"
                                            onError={async (e) => {
                                              try {
                                                const refetchedMedia =
                                                  await firestoreRepository.refetchDownloadUrls(
                                                    entityId.current,
                                                    isOrgUser.current,
                                                    media
                                                  );
                                                if (refetchedMedia) {
                                                  const isImage =
                                                    refetchedMedia.globalId.includes(
                                                      "IMAGE"
                                                    );
                                                  e.target.src =
                                                    refetchedMedia.thumbnailURL;
                                                  if (isImage) {
                                                    setImages((prevImages) => {
                                                      const currentImages =
                                                        prevImages[
                                                          session.globalId
                                                        ] || [];
                                                      return {
                                                        ...prevImages,
                                                        [session.globalId]: [
                                                          ...currentImages.filter(
                                                            (image) =>
                                                              image.globalId !==
                                                              media.globalId
                                                          ),
                                                          refetchedMedia,
                                                        ],
                                                      };
                                                    });
                                                  } else {
                                                    setVideos((prevVideos) => {
                                                      const currentVideos =
                                                        prevVideos[
                                                          session.globalId
                                                        ] || [];
                                                      return {
                                                        ...prevVideos,
                                                        [session.globalId]: [
                                                          ...currentVideos.filter(
                                                            (video) =>
                                                              video.globalId !==
                                                              media.globalId
                                                          ),
                                                          refetchedMedia,
                                                        ],
                                                      };
                                                    });
                                                  }
                                                }
                                              } catch (error) {
                                                console.error(
                                                  "Error refreshing URL:",
                                                  error
                                                );
                                              }
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={placeHolderThumbnail}
                                            className="grid_image"
                                            quality="50"
                                            loading="lazy"
                                          />
                                        )}
                                      </React.Fragment>
                                    );
                                  });
                                })()}
                              </div>
                            ) : (
                              <div className="grid">
                                {(() => {
                                  const mediaArray = [
                                    ...(images[session.globalId] || []),
                                  ].sort(
                                    (a, b) =>
                                      new Date(b.createdAt.toDate()) -
                                      new Date(a.createdAt.toDate())
                                  );
                                  return [...Array(4)].map((_, index) => {
                                    const media = mediaArray[index];
                                    return (
                                      <React.Fragment
                                        key={`${session.globalId}_${index}`}
                                      >
                                        {media ? (
                                          <img
                                            src={media.thumbnailURL}
                                            className="grid_image"
                                            quality="50"
                                            loading="lazy"
                                            onError={async (e) => {
                                              try {
                                                const refetchedMedia =
                                                  await firestoreRepository.refetchDownloadUrls(
                                                    entityId.current,
                                                    isOrgUser.current,
                                                    media
                                                  );

                                                setImages((prevImages) => ({
                                                  ...prevImages,
                                                  [session.globalId]: [
                                                    ...prevImages[
                                                      session.globalId
                                                    ].filter(
                                                      (image) =>
                                                        image.globalId !==
                                                        media.globalId
                                                    ),
                                                    refetchedMedia,
                                                  ],
                                                }));
                                              } catch (error) {
                                                console.error(
                                                  "Error refreshing URL:",
                                                  error
                                                );
                                              }
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={placeHolderThumbnail}
                                            className="grid_image"
                                            quality="50"
                                            loading="lazy"
                                          />
                                        )}
                                      </React.Fragment>
                                    );
                                  });
                                })()}
                              </div>
                            )}{" "}
                            <Link
                              className={`hover_session ${
                                sessionWithActiveModal === session.globalId
                                  ? "show"
                                  : ""
                              }`}
                              to={`/sessions/${session.globalId}`}
                              onClick={() => {
                                analyticsSessionOpen();
                                persistStorageParams();
                              }}
                            ></Link>
                            <div className="dots_session">
                              <div
                                className="session_dots_container"
                                key={session.globalId}
                              >
                                <IconButton
                                  className="session_dots_button"
                                  key={session.globalId}
                                  aria-label="more"
                                  id="long-button"
                                  aria-haspopup="true"
                                  onClick={() =>
                                    sessionWithActiveModal == session.globalId
                                      ? setSessionWithActiveModal(null)
                                      : setSessionWithActiveModal(
                                          session.globalId
                                        )
                                  }
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                {session &&
                                  sessionWithActiveModal ==
                                    session.globalId && (
                                    <>
                                      <ModalEditSession
                                        sessionObj={session}
                                        allPatients={allPatients}
                                        patientName={
                                          patients && patients[session.globalId]
                                            ? patients[session.globalId]
                                                .patientId
                                            : undefined
                                        }
                                        allTagsArray={allTags}
                                        entityId={entityId.current}
                                        isOrgUser={isOrgUser.current}
                                        reloadCallback={() =>
                                          setNeedReload(!needReload)
                                        }
                                        owner={session.owner}
                                        wrtPermissions={wrtPermissions.current}
                                        currentUser={currentUser.uid}
                                      />
                                      <ModalDeleteSession
                                        sessionId={session.globalId}
                                        entityId={entityId.current}
                                        isOrgUser={isOrgUser.current}
                                        reloadCallback={() =>
                                          setNeedReload(!needReload)
                                        }
                                        dltPermission={dltPermission.current}
                                        sessionOwner={session.owner}
                                        currentUser={currentUser.uid}
                                      />
                                    </>
                                  )}
                              </div>
                            </div>
                            <div
                              className="session_info_container"
                              style={{ alignItems: "center" }}
                            >
                              <div className="patient">
                                {
                                  // Add patient name with ellipsis if it's too long
                                  patients[session.globalId]
                                    ? patients[session.globalId].patientId
                                        .length > 12
                                      ? patients[
                                          session.globalId
                                        ].patientId.slice(0, 12 / 2) +
                                        "..." +
                                        patients[
                                          session.globalId
                                        ].patientId.slice(-12 / 2)
                                      : patients[session.globalId].patientId
                                    : ""
                                }
                              </div>
                              {session && isOrgUser.current === true ? (
                                <Owner uid={session.owner} />
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="session_info_container">
                              <div className="timestamp">
                                {session.createdAt
                                  ?.toDate()
                                  .toLocaleTimeString("en-GB", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: false,
                                  })}
                              </div>
                              <div className="tags_style">
                                {session.tags && session.tags.length > 0
                                  ? (() => {
                                      const tagsText = session.tags
                                        .slice(0, 2)
                                        .map((tag) => `#${tag.trim()}`)
                                        .join("  ");
                                      return tagsText.length > 14
                                        ? tagsText.slice(0, 14) + "..."
                                        : tagsText;
                                    })()
                                  : ""}
                              </div>
                            </div>
                          </Grid>,
                        ])}
                      </Grid>
                    </div>
                  ))}
                </Grid>
              )}
            </Container>
          ) : (
            <div>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh",
                  }}
                >
                  <CircularProgress style={{ color: "rgb(143, 79, 255)" }} />
                </div>
              ) : (
                <div>{isOrgUser.current ? <FreeOrg /> : <FreeUser />}</div>
              )}
            </div>
          )}
        </div>
      )}
    </Layout>
  );
}
