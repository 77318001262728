import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./login/login";
import Password from "./login/password";
import Forgot from "./login/forgot";
import { AuthProvider, useAuth } from "../context/AuthContext";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./dashboard/Dashboard";
import Session from "./dashboard/SessionDetailedView";
import SignUp from "./login/signUp";
import AccountSettings from "./account/userData";
import PersonalInfo from "./login/personal_info";
import Success from "./subscription/success";
import Subscription from "./dashboard/free";
import Layout from "./Layout";
import StoragePage from "./subscription/storage";
import SubsPage from "./subscription/subscription";
import { createContext, useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import Users from "./dashboard/users";
import firestoreRepository from "../repositories/firestoreRepository";

export const UploadContext = createContext();

export default function App() {
  const { currentUser } = useAuth();
  const [sideBarWidth, setSideBarWidth] = useState("");
  const [renderVideo, setRenderVideo] = useState(null);
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [sessionId, setSessionId] = useState("");
  const [uploadThumbnails, setUploadThumbnails] = useState([]);
  const [remainingTimes, setRemainingTimes] = useState({});
  const [selectedOwner, setSelectedOwner] = useState("");
  const [closeUpload, setCloseUpload] = useState(() => {
    const storedValue = localStorage.getItem("isClosed");
    return storedValue ? JSON.parse(storedValue) : true;
  });
  const [userInfo, setUserInfo] = useState({});
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const codeStr = code ? `?code=${code}` : "";

  useEffect(() => {
    window.Intercom("shutdown");
    window.Intercom("boot");

    if (currentUser?.uid) {
      const userRef = firestoreRepository.getUserReference(currentUser.uid);
      const unsub = onSnapshot(userRef, (snapshot) => {
        setUserInfo(snapshot.data());
      });
      return unsub;
    }
  }, [currentUser]);

  return (
    <div>
      <UploadContext.Provider
        value={{
          sideBarWidth,
          setSideBarWidth,
          renderVideo,
          setRenderVideo,
          files,
          setFiles,
          uploadProgress,
          setUploadProgress,
          sessionId,
          setSessionId,
          uploadThumbnails,
          setUploadThumbnails,
          remainingTimes,
          setRemainingTimes,
          closeUpload,
          setCloseUpload,
          selectedOwner,
          setSelectedOwner,
        }}
      >
        <AuthProvider>
          <Router>
            <Routes>
              <Route exact path="/*" element={<PrivateRoute />}>
                <Route exact path="/*" element={<Dashboard />} />
              </Route>
              <Route
                path="/login"
                element={
                  currentUser?.emailVerified === true ? (
                    <Navigate to={"/" + codeStr} />
                  ) : (
                    <Login />
                  )
                }
              />
              <Route
                path="/email"
                element={
                  currentUser?.emailVerified === true ? (
                    <Navigate to={"/" + codeStr} />
                  ) : (
                    <Password />
                  )
                }
              />
              <Route
                path="/forgot-password"
                element={
                  currentUser?.emailVerified === true ? (
                    <Navigate to={"/" + codeStr} />
                  ) : (
                    <Forgot />
                  )
                }
              />
              <Route path="/sessions/:id" element={<Session />} />
              <Route
                path="/sign-up"
                element={
                  currentUser?.emailVerified === true ? (
                    <Navigate to={"/" + codeStr} />
                  ) : (
                    <SignUp />
                  )
                }
              />
              <Route path="/account" element={<AccountSettings />} />
              <Route
                path="/information"
                element={
                  currentUser?.emailVerified === true &&
                  userInfo?.firstName?.length > 0 &&
                  userInfo?.country?.length > 0 &&
                  userInfo?.speciality?.length > 0 ? (
                    <Navigate to={"/" + codeStr} />
                  ) : (
                    <PersonalInfo />
                  )
                }
              />
              <Route path="/success" element={<Success />} />
              <Route path="/storage" element={<StoragePage />} />
              <Route path="/users" element={<Users />} />

              <Route path="/manage" element={<SubsPage />} />
              <Route
                path="/subscription"
                element={
                  <Layout>
                    <Subscription />
                  </Layout>
                }
              />
            </Routes>
          </Router>
        </AuthProvider>
      </UploadContext.Provider>
    </div>
  );
}
