import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import {
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";
import { getAnalytics, setUserProperties } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import firestoreRepository from "./repositories/firestoreRepository";

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});
export const functions = getFunctions(app, "europe-west3");
export const storage = getStorage(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
setUserProperties(analytics, {
  app: "Connect",
});

const googleProvider = new GoogleAuthProvider();
export const SignInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const userDoc = await firestoreRepository.getUserDocument(user.uid);
    if (!userDoc.exists()) {
      const userData = {
        uid: user.uid,
        email: user.email,
        firstName: user.displayName,
        signinMethod: "google",
      };
      await firestoreRepository.createUser(user.uid, userData);
    }
  } catch (err) {
    console.log(err);
  }
};

const provider = new OAuthProvider("apple.com");
export const signInWithApple = async () => {
  try {
    const res = await signInWithPopup(auth, provider);
    const user = res.user;
    const userDoc = await firestoreRepository.getUserDocument(user.uid);
    if (!userDoc.exists()) {
      const userData = {
        uid: user.uid,
        email: user.email,
        firstName: user.displayName,
        signinMethod: "apple",
      };
      await firestoreRepository.createUser(user.uid, userData);
    }
  } catch (err) {}
};

export const auth = app.auth();
export default app;
