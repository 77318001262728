import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";

export const downloadFiles = async (files) => {
  try {
    const generateSignedUrl = httpsCallable(functions, "generateSignedUrls");

    const filePaths = files.map((file) => file.url);

    const data = { filePaths: filePaths };

    const result = await generateSignedUrl(data);

    const signedUrls = result.data.signedUrls;
    const downloadFile = (fileData, delay) => {
      setTimeout(() => {
        const downloadUrl = fileData.signedUrl;
        const anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = fileData.filePath.split("/").pop();
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }, delay);
    };

    // Iterate over each signed URL and trigger download with a delay
    signedUrls.forEach((fileData, index) => {
      downloadFile(fileData, index * 1000); // 1 second delay between downloads
    });
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};
